<template>
  <div class="box">
    <van-pull-refresh v-model="isDownLoading" @refresh="onDownRefresh">
      <van-list
        class="content-info"
        v-model="isUpLoading"
        :finished="upFinished"
        finished-text="没有更多了"
        @load="onLoadList"
        :immediate-check="false"
        offset="10"
      >
        <div class="content" v-for="(item, indexs) in list" :key="item.id">
          <van-image
            width="60"
            height="60"
            class="vantImage"
            lazy-load
            :src="item.logo"
          />
          <div class="centerInfo">
            <span class="text">{{ item.name }}</span>
            <div class="star">
              <van-rate
                :value="Number(item.score)"
                :size="10"
                color="#ffd346"
                allow-half
                void-icon="star"
                void-color="#eee"
              />
              <div>
                <span class="info">{{ item.score }}</span>
              </div>
            </div>
            <div>
              <span class="downloadnum">下载：{{ item.downnum }}次</span>
            </div>
            <div class="gameInfo">
              <span
                v-for="(info, index) in item.lable"
                :key="index"
                class="gameInfoText"
                >{{ info.lable }}</span
              >
            </div>
          </div>
          <div class="install">
            <div
              class="downloads"
              ref="textRef"
              @click="downLoad(item.downurl, indexs)"
            >
              立即试玩
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getList } from "../api/index";
import { Hybrid } from "../common/app";
export default {
  data() {
    return {
      list: [],
      page: 1,
      num: 10,
      isUpLoading: false, //上拉加载
      upFinished: false, // 上拉加载完毕
      isDownLoading: false, // 下拉刷新
    };
  },
  created() {
    this.getroadList();
  },
  mounted() {
    Hybrid.downLoadGame = this.downLoad;
    Hybrid.changeToReset = this.changeReset;
  },
  methods: {
    downLoad(url, index) {
      if (!url) {
        this.$toast({
          message: "下载地址不存在",
        });
        return;
      }
      window.location.href = url;
      this.changeText(index);
    },
    changeText(index) {
      this.$refs.textRef[index].innerHTML = "下载中";
    },
    changeReset(url) {
      this.list.map((res, index) => {
        console.log(url, "传的参数");
        console.log(res.downurl, "作判断的值");
        if (res.downurl == url) {
          this.$refs.textRef[index].innerHTML = "立即试玩";
        }
      });
    },
    onDownRefresh() {
      this.page = 1;
      this.upFinished = false; // 不写这句会导致上拉到底过后在下拉刷新将不能触发下拉加载事件
      this.getroadList(); // 加载数据
    },
    getroadList() {
      const _self = this;
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      let data = {
        page: _self.page,
        num: _self.num,
      };
      getList(data)
        .then((res) => {
          if (!res.status == 200) return;
          let rows = res.data.data;
          if (rows == null || rows.length === 0) {
            // 加载结束
            _self.upFinished = true;
            return;
          }
          if (rows.length < _self.num) {
            // 最后一页不足10条的情况
            _self.upFinished = true;
          }
          if (_self.page === 1) {
            _self.list = rows;
          } else {
            _self.list = _self.list.concat(rows);
          }
        })
        .catch((error) => {
          this.$toast({
            message: "获取资源列表异常{" + error + "}",
          });
        })
        .finally(() => {
          _self.isDownLoading = false;
          _self.isUpLoading = false;
        });
    },
    onLoadList() {
      this.page++;
      this.getroadList();
    },
  },
};
</script>

<style lang="scss" scoped>
html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.box {
  background: #fdf9e3;
}
.van-pull-refresh {
  background: #eddfbc;
  border-radius: 0.25rem;
  overflow: hidden;
}
.content-info {
  padding: 0.2rem;
}
.content {
  display: flex;
  position: relative;
  margin-bottom: 0.2rem;
  .centerInfo {
    margin-left: 0.15rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .text {
      font-size: 0.41rem;
      font-weight: bold;
      color: #333333;
    }
    .star {
      margin-top: 0.1rem;
      display: flex;
      align-items: center;
      .info {
        color: #666666;
        margin-left: 0.15rem;
        font-size: 0.35rem;
      }
    }
    .downloadnum {
      color: #999999;
      font-size: 0.3rem;
    }
    .gameInfo {
      .gameInfoText {
        font-size: 0.3rem;
        color: #666666;
        margin-right: 0.1rem;
      }
      .gameInfoText:not(:first-child) {
        border-left: 1px solid #999999;
        padding-left: 0.1rem;
      }
    }
  }
}
.install {
  display: flex;
  position: absolute;
  right: 0.15rem;
  top: 50%;
  transform: translate(0, -50%);
}
.vantImage {
  overflow: hidden;
  border-radius: 0.2rem;
  // margin-left: 0.1rem;
}
.downloads {
  width: 2.15rem;
  height: 0.8rem;
  font-size: 0.43rem;
  background: #e59b50;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2e261e;
  border-radius: 0.12rem;
}

::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
  visibility: hidden;
}
</style>
